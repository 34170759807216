import { createRouter, createWebHistory } from "vue-router";
import { isMobile } from "@/utils/utils";

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/:code",
            component: isMobile()
                ? () => import("@/pages/Home/index.m.vue")
                : () => import("@/pages/Home/index.p.vue"),
        },
        {
            path: "/detail/:id",
            component: isMobile()
                ? () => import("@/pages/Detail/index.m.vue")
                : () => import("@/pages/Detail/index.p.vue"),
        },
    ],
});
